import For from "@/components/Tools/For/For";
import useHeaderMenu from "@/hooks/shared/useHeaderMenu";
import useProfile from "@/hooks/shared/useProfile";
import Cookie from "@/utils/cookies.utils";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useId, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import "swiper/css";
import NavMobileModal from "../NavMobileModal/NavMobileModal";
import useGetCartList from "@/hooks/pages/Cart/useGetCartList";
import useLogout from "@/hooks/shared/useLogout";
import AppTranslation from "@/components/Tools/AppTranslation/AppTranslation";
import translate from "@/utils/translate.utils";
import { Locale } from "@/types/global.types";
import { useSearchBar } from "@/global-state/global.state";

interface Props {
  locale: Locale
}

type SearchType = {
  textQuery: string;
  textQueryMobile: string
};

function Header({ locale }: Props) {

  const { data: categoryMenu } = useHeaderMenu();
  const { data: profileData } = useProfile();
  const { data: cartListData } = useGetCartList();
  const { mutateLogout, isLoadingLogOut } = useLogout()

  const router = useRouter();
  const listKey = useId();
  const childKey = useId();
  const subChildKey = useId();

  const accessToken = Cookie.get("access_token");

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleOpenAccountDropdown = (
    e: React.UIEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SearchType>();

  const onSubmit: SubmitHandler<SearchType> = ({ textQuery }) => {
    router.push({
      pathname: `/search`,
      query: {
        textQuery,
      },
    });
  };

  const onSubmitMobile: SubmitHandler<SearchType> = ({ textQueryMobile }) => {
    router.push({
      pathname: `/search`,
      query: {
        textQuery: textQueryMobile
      },
    });
  };

  const mobileMenuBtn = useRef<HTMLDivElement>(null);

  const hadleLogOut = () => {
    if (!isLoadingLogOut) {
      mutateLogout(undefined, {
        onSuccess: () => {
          Cookie.delete('access_token')
          window.location.reload()
        }
      })
    }
  }


  const { isShow, setIsShow } = useSearchBar()

  return (
    <>
      <Wrapper>
        {categoryMenu?.data && (
          <NavMobileModal data={categoryMenu?.data} clickTarget={mobileMenuBtn} />
        )}
        <nav>
          <div className="nav-top-container">
            <div className="navbar-top">
              <Link className="main-links" href="/">
                <div className="img-container">
                  <img
                    src={
                      require("@/public/assets/img/mb2-main-logo.svg").default.src
                    }
                    alt="ModaBu logo"
                  />
                </div>
              </Link>
              <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
                <input
                  {...register("textQuery")}
                  type="search"
                  className="search-input"
                  placeholder={translate('searchInputText', locale)}
                  autoComplete="off"
                />
                <button type="submit" className="search-btn">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L21.2929 22.7071ZM22.7071 21.2929L16.9928 15.5786L15.5786 16.9928L21.2929 22.7071L22.7071 21.2929ZM10.5714 18.1429C6.38984 18.1429 3 14.753 3 10.5714H1C1 15.8576 5.28527 20.1429 10.5714 20.1429V18.1429ZM18.1429 10.5714C18.1429 14.753 14.753 18.1429 10.5714 18.1429V20.1429C15.8576 20.1429 20.1429 15.8576 20.1429 10.5714H18.1429ZM10.5714 3C14.753 3 18.1429 6.38984 18.1429 10.5714H20.1429C20.1429 5.28527 15.8576 1 10.5714 1V3ZM10.5714 1C5.28527 1 1 5.28527 1 10.5714H3C3 6.38984 6.38984 3 10.5714 3V1Z"
                        fill="#05061E"
                      />
                    </g>
                  </svg>
                </button>
              </form>
              <div className="nav-group">
                <Link className="main-links" href="/cart">
                  <div className="cart">
                    <div className="cart-icon-container">
                      <svg
                        className="icon-svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.75">
                          <path
                            d="M3.84036 10.4233L2.84647 10.3129L2.84647 10.3129L3.84036 10.4233ZM2.59591 20.25L1.60474 20.1175C1.60375 20.1248 1.60285 20.1322 1.60203 20.1396L2.59591 20.25ZM21.4011 20.25L22.395 20.1396C22.3944 20.1345 22.3938 20.1294 22.3932 20.1244L21.4011 20.25ZM20.1566 10.4233L19.1628 10.5337C19.1633 10.5388 19.1639 10.5439 19.1646 10.5489L20.1566 10.4233ZM3.49854 13.5L4.48971 13.6325L4.49119 13.6215L4.49242 13.6104L3.49854 13.5ZM4.18613 23.0267H19.8109V21.0267H4.18613V23.0267ZM22.3932 20.1244L21.1487 10.2977L19.1646 10.5489L20.409 20.3756L22.3932 20.1244ZM18.5664 8H5.43057V10H18.5664V8ZM21.1505 10.3129C21.0042 8.99615 19.8913 8 18.5664 8V10C18.8722 10 19.129 10.2299 19.1628 10.5337L21.1505 10.3129ZM19.8109 23.0267C21.3605 23.0267 22.5661 21.6797 22.395 20.1396L20.4072 20.3604C20.4467 20.7159 20.1685 21.0267 19.8109 21.0267V23.0267ZM1.60203 20.1396C1.4309 21.6797 2.6365 23.0267 4.18613 23.0267V21.0267C3.82852 21.0267 3.55031 20.7159 3.5898 20.3604L1.60203 20.1396ZM4.83424 10.5337C4.868 10.2299 5.12484 10 5.43057 10V8C4.10574 8 2.99278 8.99615 2.84647 10.3129L4.83424 10.5337ZM8.19865 9V7.05H6.19865V9H8.19865ZM15.7986 7.05V9H17.7986V7.05H15.7986ZM11.9986 3.25C14.0973 3.25 15.7986 4.95132 15.7986 7.05H17.7986C17.7986 3.84675 15.2019 1.25 11.9986 1.25V3.25ZM8.19865 7.05C8.19865 4.95132 9.89997 3.25 11.9986 3.25V1.25C8.7954 1.25 6.19865 3.84675 6.19865 7.05H8.19865ZM2.84647 10.3129L2.50465 13.3896L4.49242 13.6104L4.83424 10.5337L2.84647 10.3129ZM2.50736 13.3675L1.60474 20.1175L3.58709 20.3825L4.48971 13.6325L2.50736 13.3675Z"
                            fill="#05061E"
                          />
                        </g>
                      </svg>
                      <span className="cart-count">
                        {cartListData?.data?.items.length || 0}
                      </span>
                    </div>
                    <span className="cart-text">{translate('cart', locale)}</span>
                  </div>
                </Link>
                <Link className="main-links" href="/account/favorite">
                  <div className="favorite">
                    <div className="favorite-icon-container">
                      <svg
                        className="icon-svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.75">
                          <path
                            d="M12.0239 5.60885C9.58476 0.111333 2.11179 1.18962 1.16778 6.84681C0.757204 9.24135 0.602893 13.6667 6.90266 18.8803C8.39774 20.1175 10.126 21.1876 12.0239 22C19.104 18.9694 23.824 12.3525 22.88 6.84681C21.936 1.18962 14.463 0.111333 12.0239 5.60885Z"
                            stroke="#0C0D1E"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                      <span className="favorite-count">0</span>
                    </div>
                  </div>
                </Link>
                <Link
                  href={"/login"}
                  className="account-btn"
                  onClick={accessToken ? handleOpenAccountDropdown : undefined}
                >
                  <div className="account">
                    <svg
                      className="icon-svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.75">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.7297 6.61921C16.7297 9.17055 14.5195 11.2384 11.7926 11.2384C9.06565 11.2384 6.85547 9.17055 6.85547 6.61921C6.85547 4.06787 9.06565 2 11.7926 2C14.5195 2 16.7297 4.06787 16.7297 6.61921Z"
                          stroke="#0C0D1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                        <path
                          d="M21 22C21 17.5813 16.971 14 12 14C9.28913 14 6.85841 15.0651 5.20848 16.7502C3.83287 18.1551 3 19.991 3 22"
                          stroke="#0C0D1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </g>
                    </svg>
                    {isDropdownOpen ? (
                      <div className="account-dropdown">
                        <div className="user-info">
                          <div className="img-container">
                            <img
                              src={
                                require("@/public/assets/img/mb2-default-avatar.svg")
                                  .default.src
                              }
                              alt="user avatar"
                            />
                          </div>
                          <div className="user-name">
                            <p className="name">
                              {profileData?.data.firstName +
                                " " +
                                profileData?.data.lastName}
                            </p>
                            <p className="client-code">
                              {profileData?.data.clientCode}
                            </p>
                          </div>
                        </div>
                        <div className="user-dropdown-links">
                          <Link href="/account/order">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M2 12.1473V4.44105L8 1.14126L14 4.44105V12.1473L8 14.8998L2 12.1473Z"
                                  stroke="#0C0D1E"
                                  strokeWidth={2}
                                />
                              </g>
                            </svg>
                            {translate('myOrders', locale)}
                          </Link>
                          <Link href="/account/favorite">
                            <svg
                              width={18}
                              height={16}
                              viewBox="0 0 18 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M9.01723 3.73939C7.39114 0.0743843 2.40916 0.793244 1.77982 4.5647C1.5061 6.16106 1.40323 9.1113 5.60308 12.587C6.59979 13.4119 7.75199 14.1252 9.01723 14.6668C13.7373 12.6464 16.884 8.23514 16.2546 4.5647C15.6253 0.793244 10.6433 0.0743843 9.01723 3.73939Z"
                                  stroke="#0C0D1E"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                            {translate('favorites', locale)}
                          </Link>
                          <Link href="/account/address">
                            <svg
                              width={16}
                              height={18}
                              viewBox="0 0 16 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.00156 10.061C9.17916 10.061 10.1349 9.10584 10.1349 7.929C10.1349 6.75217 9.17916 5.79707 8.00156 5.79707C6.82396 5.79707 5.86823 6.75217 5.86823 7.929C5.86823 9.10584 6.82396 10.061 8.00156 10.061Z"
                                  stroke="#05061E"
                                  strokeWidth={2}
                                  strokeLinecap="square"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.4016 7.929C14.4016 13.2589 9.06823 16.4568 8.00156 16.4568C6.9349 16.4568 1.60156 13.2589 1.60156 7.929C1.60156 4.39744 4.4677 1.5332 8.00156 1.5332C11.5354 1.5332 14.4016 4.39744 14.4016 7.929Z"
                                  stroke="#05061E"
                                  strokeWidth={2}
                                  strokeLinecap="square"
                                />
                              </g>
                            </svg>
                            {translate('addresses', locale)}
                          </Link>
                          <Link href="/account/settings">
                            <svg
                              width={18}
                              height={18}
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.34209 1.5332L7.25036 1.99902L6.89943 3.70352C6.33836 3.91778 5.82956 4.2216 5.36982 4.5819L3.65462 4.0084L3.19383 3.86556L2.9549 4.27702L1.77409 6.25117L1.53516 6.6627L1.88503 6.96862L3.21196 8.09746C3.16396 8.39273 3.1021 8.68479 3.1021 8.99499C3.1021 9.30518 3.16396 9.5973 3.21196 9.89258L1.88503 11.0214L1.53516 11.3273L1.77409 11.7388L2.9549 13.713L3.19383 14.1255L3.65462 13.9816L5.36982 13.4081C5.82956 13.7684 6.33836 14.0722 6.89943 14.2865L7.25036 15.9909L7.34209 16.4568H10.6605L10.7533 15.9909L11.1032 14.2865C11.6642 14.0722 12.173 13.7684 12.6328 13.4081L14.348 13.9816L14.8088 14.1255L15.0488 13.713L16.2285 11.7388L16.4685 11.3273L16.1176 11.0214L14.7906 9.89258C14.8397 9.5973 14.9005 9.30518 14.9005 8.99499C14.9005 8.68479 14.8397 8.39273 14.7906 8.09746L16.1176 6.96862L16.4685 6.6627L16.2285 6.25117L15.0488 4.27702L14.8088 3.86556L14.348 4.0084L12.6328 4.5819C12.173 4.2216 11.6642 3.91778 11.1032 3.70352L10.7533 1.99902L10.6605 1.5332H7.34209Z"
                                  stroke="#05061E"
                                  strokeWidth={2}
                                  strokeLinecap="square"
                                  strokeLinejoin="round"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.1348 8.99499C11.1348 10.1718 10.179 11.127 9.00143 11.127C7.82383 11.127 6.8681 10.1718 6.8681 8.99499C6.8681 7.81815 7.82383 6.86309 9.00143 6.86309C10.179 6.86309 11.1348 7.81815 11.1348 8.99499Z"
                                  stroke="#05061E"
                                  strokeWidth={2}
                                  strokeLinecap="square"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                            {translate('settings', locale)}
                          </Link>
                        </div>
                        <div className="logout-btn"
                          onClick={hadleLogOut}
                        >
                          <svg
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.5">
                              <path
                                d="M9.00208 10.0665V1.5332M3.79436 3.6651C2.40662 5.01995 1.53516 6.90255 1.53516 8.99505C1.53516 13.1161 4.87916 16.4665 9.00182 16.4665C13.1266 16.4665 16.4685 13.1161 16.4685 8.99505C16.4685 6.90575 15.6077 5.01995 14.2232 3.6651"
                                stroke="#05061E"
                                strokeWidth={2}
                                strokeLinecap="round"
                              />
                            </g>
                          </svg>
                          {translate('logOut', locale)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="navbar-mobile">
            <Link href="/">
              <div className="img-container">
                <img
                  src={
                    require("@/public/assets/img/mb2-main-logo.svg").default.src
                  }
                  alt="ModaBu logo"
                />
              </div>
            </Link>
            <div className="nav-group">
              <button className="mobile-nav-search" onClick={setIsShow.bind(null, !isShow)}>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.75">
                    <path
                      d="M17.2929 19.7071C17.6834 20.0976 18.3166 20.0976 18.7071 19.7071C19.0976 19.3166 19.0976 18.6834 18.7071 18.2929L17.2929 19.7071ZM18.7071 18.2929L14.1357 13.7215L12.7215 15.1357L17.2929 19.7071L18.7071 18.2929ZM8.85714 15.7143C5.62233 15.7143 3 13.092 3 9.85714H1C1 14.1965 4.51776 17.7143 8.85714 17.7143V15.7143ZM14.7143 9.85714C14.7143 13.092 12.092 15.7143 8.85714 15.7143V17.7143C13.1965 17.7143 16.7143 14.1965 16.7143 9.85714H14.7143ZM8.85714 4C12.092 4 14.7143 6.62233 14.7143 9.85714H16.7143C16.7143 5.51776 13.1965 2 8.85714 2V4ZM8.85714 2C4.51776 2 1 5.51776 1 9.85714H3C3 6.62233 5.62233 4 8.85714 4V2Z"
                      fill="#05061E"
                    />
                  </g>
                </svg>
              </button>
              <div className="cart">
                <Link id="mobile-cart" href="/cart">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.75">
                      <path
                        d="M3.84036 10.4233L2.84647 10.3129L2.84647 10.3129L3.84036 10.4233ZM2.59591 20.25L1.60474 20.1175C1.60375 20.1248 1.60285 20.1322 1.60203 20.1396L2.59591 20.25ZM21.4011 20.25L22.395 20.1396C22.3944 20.1345 22.3938 20.1294 22.3932 20.1244L21.4011 20.25ZM20.1566 10.4233L19.1628 10.5337C19.1633 10.5388 19.1639 10.5439 19.1646 10.5489L20.1566 10.4233ZM3.49854 13.5L4.48971 13.6325L4.49119 13.6215L4.49242 13.6104L3.49854 13.5ZM4.18613 23.0267H19.8109V21.0267H4.18613V23.0267ZM22.3932 20.1244L21.1487 10.2977L19.1646 10.5489L20.409 20.3756L22.3932 20.1244ZM18.5664 8H5.43057V10H18.5664V8ZM21.1505 10.3129C21.0042 8.99615 19.8913 8 18.5664 8V10C18.8722 10 19.129 10.2299 19.1628 10.5337L21.1505 10.3129ZM19.8109 23.0267C21.3605 23.0267 22.5661 21.6797 22.395 20.1396L20.4072 20.3604C20.4467 20.7159 20.1685 21.0267 19.8109 21.0267V23.0267ZM1.60203 20.1396C1.4309 21.6797 2.6365 23.0267 4.18613 23.0267V21.0267C3.82852 21.0267 3.55031 20.7159 3.5898 20.3604L1.60203 20.1396ZM4.83424 10.5337C4.868 10.2299 5.12484 10 5.43057 10V8C4.10574 8 2.99278 8.99615 2.84647 10.3129L4.83424 10.5337ZM8.19865 9V7.05H6.19865V9H8.19865ZM15.7986 7.05V9H17.7986V7.05H15.7986ZM11.9986 3.25C14.0973 3.25 15.7986 4.95132 15.7986 7.05H17.7986C17.7986 3.84675 15.2019 1.25 11.9986 1.25V3.25ZM8.19865 7.05C8.19865 4.95132 9.89997 3.25 11.9986 3.25V1.25C8.7954 1.25 6.19865 3.84675 6.19865 7.05H8.19865ZM2.84647 10.3129L2.50465 13.3896L4.49242 13.6104L4.83424 10.5337L2.84647 10.3129ZM2.50736 13.3675L1.60474 20.1175L3.58709 20.3825L4.48971 13.6325L2.50736 13.3675Z"
                        fill="#05061E"
                      />
                    </g>
                  </svg>
                  <span className="mobile-cart-count">
                    {cartListData?.data?.items.length || 0}
                  </span>
                </Link>
              </div>
              <div className="cart">
                <Link id="mobile-fav" href="/account/favorite">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.75">
                      <path
                        d="M12.0239 5.60885C9.58476 0.111333 2.11179 1.18962 1.16778 6.84681C0.757204 9.24135 0.602893 13.6667 6.90266 18.8803C8.39774 20.1175 10.126 21.1876 12.0239 22C19.104 18.9694 23.824 12.3525 22.88 6.84681C21.936 1.18962 14.463 0.111333 12.0239 5.60885Z"
                        stroke="#0C0D1E"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                  <span className="mobile-fav-count">0</span>
                </Link>
              </div>
              <div className="favorite account-mobile">
                <Link
                  href={"/login"}
                  className="account-btn"
                  onClick={accessToken ? handleOpenAccountDropdown : undefined}
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.75">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.7297 6.61921C16.7297 9.17055 14.5195 11.2384 11.7926 11.2384C9.06565 11.2384 6.85547 9.17055 6.85547 6.61921C6.85547 4.06787 9.06565 2 11.7926 2C14.5195 2 16.7297 4.06787 16.7297 6.61921Z"
                        stroke="#0C0D1E"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                      <path
                        d="M21 22C21 17.5813 16.971 14 12 14C9.28913 14 6.85841 15.0651 5.20848 16.7502C3.83287 18.1551 3 19.991 3 22"
                        stroke="#0C0D1E"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </g>
                  </svg>
                </Link>
              </div>
              <div className="menu" ref={mobileMenuBtn}>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.75">
                    <path
                      d="M2 2H18"
                      stroke="#0C0D1E"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M2 10H18"
                      stroke="#0C0D1E"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M2 18H18"
                      stroke="#0C0D1E"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <div className="account-mobile-menu">
              <div className="top-container">
                <div className="account-dropdown">
                  <div className="user-info">
                    <div className="container">
                      <div className="img-container">
                        <img
                          src={
                            require("@/public/assets/img/mb2-default-avatar.svg")
                              .default.src
                          }
                          alt="user avatar"
                        />
                      </div>
                      <div className="user-name">
                        <p className="name">
                          {profileData?.data.firstName +
                            " " +
                            profileData?.data.lastName}
                        </p>
                        <p className="client-code">
                          {profileData?.data.clientCode}
                        </p>
                      </div>
                    </div>
                    <button
                      className="close-btn"
                      onClick={handleOpenAccountDropdown}
                    >
                      <img
                        src={require("@/public/assets/img/x.svg").default.src}
                        alt="close icon"
                      />
                    </button>
                  </div>
                  <div className="user-dropdown-links">
                    <Link href="/account/order">
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M2 12.1473V4.44105L8 1.14126L14 4.44105V12.1473L8 14.8998L2 12.1473Z"
                            stroke="#0C0D1E"
                            strokeWidth={2}
                          />
                        </g>
                      </svg>
                      {translate('myOrders', locale)}

                    </Link>
                    <Link href="/account/favorite">
                      <svg
                        width={18}
                        height={16}
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.01723 3.73939C7.39114 0.0743843 2.40916 0.793244 1.77982 4.5647C1.5061 6.16106 1.40323 9.1113 5.60308 12.587C6.59979 13.4119 7.75199 14.1252 9.01723 14.6668C13.7373 12.6464 16.884 8.23514 16.2546 4.5647C15.6253 0.793244 10.6433 0.0743843 9.01723 3.73939Z"
                            stroke="#0C0D1E"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                      {translate('favorites', locale)}
                    </Link>
                    <Link href="/account/address">
                      <svg
                        width={16}
                        height={18}
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.00156 10.061C9.17916 10.061 10.1349 9.10584 10.1349 7.929C10.1349 6.75217 9.17916 5.79707 8.00156 5.79707C6.82396 5.79707 5.86823 6.75217 5.86823 7.929C5.86823 9.10584 6.82396 10.061 8.00156 10.061Z"
                            stroke="#05061E"
                            strokeWidth={2}
                            strokeLinecap="square"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.4016 7.929C14.4016 13.2589 9.06823 16.4568 8.00156 16.4568C6.9349 16.4568 1.60156 13.2589 1.60156 7.929C1.60156 4.39744 4.4677 1.5332 8.00156 1.5332C11.5354 1.5332 14.4016 4.39744 14.4016 7.929Z"
                            stroke="#05061E"
                            strokeWidth={2}
                            strokeLinecap="square"
                          />
                        </g>
                      </svg>
                      {translate('addresses', locale)}
                    </Link>
                    <Link href="/account/settings">
                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.34209 1.5332L7.25036 1.99902L6.89943 3.70352C6.33836 3.91778 5.82956 4.2216 5.36982 4.5819L3.65462 4.0084L3.19383 3.86556L2.9549 4.27702L1.77409 6.25117L1.53516 6.6627L1.88503 6.96862L3.21196 8.09746C3.16396 8.39273 3.1021 8.68479 3.1021 8.99499C3.1021 9.30518 3.16396 9.5973 3.21196 9.89258L1.88503 11.0214L1.53516 11.3273L1.77409 11.7388L2.9549 13.713L3.19383 14.1255L3.65462 13.9816L5.36982 13.4081C5.82956 13.7684 6.33836 14.0722 6.89943 14.2865L7.25036 15.9909L7.34209 16.4568H10.6605L10.7533 15.9909L11.1032 14.2865C11.6642 14.0722 12.173 13.7684 12.6328 13.4081L14.348 13.9816L14.8088 14.1255L15.0488 13.713L16.2285 11.7388L16.4685 11.3273L16.1176 11.0214L14.7906 9.89258C14.8397 9.5973 14.9005 9.30518 14.9005 8.99499C14.9005 8.68479 14.8397 8.39273 14.7906 8.09746L16.1176 6.96862L16.4685 6.6627L16.2285 6.25117L15.0488 4.27702L14.8088 3.86556L14.348 4.0084L12.6328 4.5819C12.173 4.2216 11.6642 3.91778 11.1032 3.70352L10.7533 1.99902L10.6605 1.5332H7.34209Z"
                            stroke="#05061E"
                            strokeWidth={2}
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.1348 8.99499C11.1348 10.1718 10.179 11.127 9.00143 11.127C7.82383 11.127 6.8681 10.1718 6.8681 8.99499C6.8681 7.81815 7.82383 6.86309 9.00143 6.86309C10.179 6.86309 11.1348 7.81815 11.1348 8.99499Z"
                            stroke="#05061E"
                            strokeWidth={2}
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                      {translate('settings', locale)}
                    </Link>
                  </div>
                  <div className="logout-btn"
                    onClick={hadleLogOut}
                  >
                    <svg
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M9.00208 10.0665V1.5332M3.79436 3.6651C2.40662 5.01995 1.53516 6.90255 1.53516 8.99505C1.53516 13.1161 4.87916 16.4665 9.00182 16.4665C13.1266 16.4665 16.4685 13.1161 16.4685 8.99505C16.4685 6.90575 15.6077 5.01995 14.2232 3.6651"
                          stroke="#05061E"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </g>
                    </svg>
                    {translate('logOut', locale)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <ul className="navlist">
            <For each={categoryMenu?.data} loading=" ">
              {(item, index) => (
                <li className="navlist-item" key={listKey + index}>
                  <Link href={"/product-banner/" + item.id}>{item.name}</Link>
                  <div className="mega-menu ">
                    <div className="mega-menu_body">
                      <div className="cat-list-container">
                        <For each={item.children}>
                          {(child, childIndex) => (
                            <div
                              className="cat-list_item"
                              key={childIndex + childKey}
                            >
                              <Link
                                className="header"
                                href={{
                                  pathname: "/" + child.slug,
                                  query: {
                                    category_id: child.id,
                                  },
                                }}
                              >
                                {child.name}
                              </Link>
                              <ul>
                                <For each={child.children}>
                                  {(subChild, subIndex) => (
                                    <li key={subIndex + subChildKey}>
                                      <Link
                                        href={{
                                          pathname: "/" + subChild.slug,
                                          query: {
                                            category_id: subChild.id,
                                          },
                                        }}
                                      >
                                        {subChild.name}
                                      </Link>
                                    </li>
                                  )}
                                </For>
                              </ul>
                            </div>
                          )}
                        </For>
                      </div>
                    </div>
                    <div className="mega-menu_img">
                      <div className="img-container">
                        <img src="" alt="subcategory" />
                        <a href="/product-list?categories=3">
                          Məhsullara bax
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.6016 12.0001L15.2016 5.6001M21.6016 12.0001L15.2016 18.4001M21.6016 12.0001H1.60156"
                              stroke="white"
                              strokeWidth={2}
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </For>
          </ul>
          <ul className="navlist-tablet"></ul>
        </nav>
      </Wrapper>
      {
        isShow ? <form className="form-group mobile"
          onSubmit={handleSubmit(onSubmitMobile)}
        >
          <input
            {...register("textQueryMobile")}
            className="search-input"
            type="search"
            placeholder={translate("searchInputText", locale)}
            autoComplete="off"
          />
          <button type="submit" className="search-btn">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path
                  d="M21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L21.2929 22.7071ZM22.7071 21.2929L16.9928 15.5786L15.5786 16.9928L21.2929 22.7071L22.7071 21.2929ZM10.5714 18.1429C6.38984 18.1429 3 14.753 3 10.5714H1C1 15.8576 5.28527 20.1429 10.5714 20.1429V18.1429ZM18.1429 10.5714C18.1429 14.753 14.753 18.1429 10.5714 18.1429V20.1429C15.8576 20.1429 20.1429 15.8576 20.1429 10.5714H18.1429ZM10.5714 3C14.753 3 18.1429 6.38984 18.1429 10.5714H20.1429C20.1429 5.28527 15.8576 1 10.5714 1V3ZM10.5714 1C5.28527 1 1 5.28527 1 10.5714H3C3 6.38984 6.38984 3 10.5714 3V1Z"
                  fill="#05061E"
                />
              </g>
            </svg>
          </button>
        </form> : <></>
      }
    </>

  );
}

export default AppTranslation(Header);

const Wrapper = styled.div`
      @media (max-width: 768px) {
        width: 100%;
      padding: 0;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 9999999;
  }
      `;
